
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.card {
  position: relative;
  height: 400px;
  width: 100%;
  background: $color-accent-lineargradient138;

  @include media-breakpoint-up(lg) {
    height: 400px;
    width: 400px;

    &.isDouble {
      width: 800px;
    }

    &.isFullWidth {
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding: $space-lg;
  color: $color-accent-fresh;
}

.headline {
  padding-bottom: $space-sm;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;

  > a {
    margin-bottom: $space-sm;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;

    > a {
      margin-right: $space-sm;
    }
  }
}
